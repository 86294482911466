import dayjs from 'dayjs';

import GroupsEntity from '@modules/groups/groupsEntity';

export enum Status {
  Active = 1,
  Inactive = 2,
}

export enum ETwoFaStatus {
  Disabled = 1,
  Enabled = 2,
}

class UsersEntity {
  username = '';

  email = '';

  fullName = '';

  value? = '';

  label? = '';

  groups: GroupsEntity[] = [];

  createdAt = '';

  id: string = '';

  phoneNumber: string = '';

  status: Status = Status.Active;

  twoFaStatus?: ETwoFaStatus;

  constructor(users: Partial<UsersEntity>) {
    if (!users) {
      return;
    }
    Object.assign(this, users);
    // convert entity type here
    this.createdAt = users.createdAt ? dayjs(users.createdAt).format('DD/MM/YYYY') : '';
    this.value = users.id ? users.id : '';
    this.label = users.username ? users.username : '';
  }

  static createListUsers(listUsers: Array<Partial<UsersEntity>>) {
    if (!Array.isArray(listUsers)) {
      return [];
    }
    return listUsers.map((users: Partial<UsersEntity>) => {
      return new UsersEntity(users);
    });
  }
}
export default UsersEntity;
